<template>
  <div>
    <Product />
  </div>
</template>
<style>
</style>

<script>
import Product from './Product.vue';

export default {
  components: { Product },
  data: () => ({}),
};
</script>
